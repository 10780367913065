import React from "react";
import { SocialIcon } from "react-social-icons";

const TitlePage = () => {
  return (
    <div className="background">
      <div
        className="container items-center flex-col flex"
        style={{ borderTop: "none" }}
      >
        <h1 className="text-center text-white text-6xl font-light my-10">
          Jan Luca Scheerer
        </h1>
        <h3 className="text-center text-neutral-300 text-2xl mb-10">
          Quantitative Technologist{" "}
          <a href="https://radix-trading.com">@Radix</a>
          <br />
          Previously SWE Intern&nbsp;
          <a href="https://www.citadelsecurities.com">@CitSec</a> and{" "}
          <a href="https://google.com">@Google</a>
          <br />
        </h3>
        <div className="w-full max-w-lg flex justify-evenly">
          <SocialIcon
            bgColor="rgb(212 212 212)"
            url="https://linkedin.com/in/jan-luca-scheerer"
          />
          <SocialIcon
            bgColor="rgb(212 212 212)"
            url="https://twitter.com/jlscheerer"
          />
          <SocialIcon
            bgColor="rgb(212 212 212)"
            url="https://github.com/jlscheerer"
          />
        </div>
      </div>
    </div>
  );
};

export default TitlePage;
