import React from "react";
import CVEntry from "../components/CVEntry";

const Experience = () => {
  return (
    <div className="background">
      <div className="container items-center flex-col flex">
        <h1 className="page-heading">Experience</h1>
        <CVEntry
          title="Radix Trading LLC"
          subtitle="Quantitative Technologist"
          time="Sep '24 - Present"
          location="Amsterdam, NL"
          icon="img/radix.png"
        >
          Core Trading Systems Team
          <br />
          <b>Technical Skills: </b>C++, Quantitative Finance
        </CVEntry>
        <CVEntry
          title="Citadel Securities"
          subtitle="Software Engineer Intern"
          time="June '23 – Sep '23"
          location="London, UK"
          icon="img/citadel.png"
        >
          Options Technology Team
          <br />
          <b>Technical Skills: </b>C++, Quantitative Finance
        </CVEntry>
        <CVEntry
          title="Google"
          subtitle="Software Engineer Intern"
          time="May '22 – Sep '22"
          location="Zürich, Switzerland"
          icon="img/google.png"
        >
          Worked with{" "}
          <a href="https://arxiv.org/pdf/2106.09799.pdf">
            Google's Graph Query Language
          </a>{" "}
          Team
          <br />
          Development of Internal Tooling (Knowledge Graph | PathQuery)
          <br />
          <b>Technical Skills: </b>C++, Relational Algebra, Google Internal
          Tooling
        </CVEntry>
        <CVEntry
          title="Uncountable"
          subtitle="Software Engineer Intern"
          link="https://uncountable.com/"
          time="Feb '22 – Apr '22"
          location="Munich, Germany"
          icon="img/uncountable.png"
        >
          End-to-End Implementation of a Classification System
          <br />
          Automatic Generation of Internal &amp; External API-Documentation
          <br />
          <b>Technical Skills: </b>TypeScript, Python3, PyTorch
        </CVEntry>
      </div>
    </div>
  );
};

export default Experience;
